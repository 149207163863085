var gnavMyAccountCardCallback = gnavMyAccountCardCallback || {};

gnavMyAccountCardCallback.myAccountConsultations = gnavMyAccountCardCallback.myAccountConsultations || {};

(function ($) {
  gnavMyAccountCardCallback.myAccountConsultations = function ($html, data, user) {
    site.endeca.instances.foundation_finder_dashboard.configuration = set_foundation_finder_dashboard_configuration($html);

    new site.endeca.instances.foundation_finder_dashboard.control(
      site.endeca.instances.foundation_finder_dashboard.configuration,
      data.foundation_finder_free_text
    );
  };
})(jQuery);
